import { getProfile, updateUser } from '@api/user';

import * as types from '../../constants/user';

import { changeUserIsAuthorized, changeAuthModalDataIsLoading } from './authActions';

const changeUserProfile = (payload) => ({
    type: types.USER_CHANGE_PROFILE,
    payload,
});

const loadProfile = () =>
    async function (dispatch) {
        dispatch(changeAuthModalDataIsLoading(true));

        try {
            const { data } = await getProfile();

            dispatch(changeUserProfile(data));
            dispatch(changeUserIsAuthorized(true));

            return data;
        } catch (error) {
            dispatch(changeUserIsAuthorized(false));
        }
    };

const updateProfile = (profile) =>
    async function (dispatch, getState) {
        try {
            const { data } = await updateUser({
                userId: getState().user.profile.id,
                data: profile,
            });

            dispatch(changeUserProfile(data));

            return data;
        } catch (e) {
            return;
        }
    };

export { changeUserProfile, loadProfile, updateProfile };
