import React from 'react';

import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import Dropdown from '@components/Common/Dropdown';

import { withTranslation, Link } from '@lib/i18n';

import { StyledHeaderDropDownItems, StyledHeaderDropDownItem } from '../HeaderDropdown';

import withRedux from './withRedux';

import './index.scss';

const AuthHeaderMenu = (props) => {
    const { localLogout, profile, t } = props;
    const isAdmin = profile.role === 'admin';

    return (
        <Dropdown
            overlayClassName="header_dropdown_items"
            trigger={['click']}
            animation="slide-up"
            placement="bottomRight"
            overlay={
                <StyledHeaderDropDownItems>
                    <Link href={`/profile/${profile.id}`}>
                        <StyledHeaderDropDownItem>
                            {t('PROFILE')}
                        </StyledHeaderDropDownItem>
                    </Link>
                    <Link href={'/certificates'}>
                        <StyledHeaderDropDownItem>
                            {t('CERTIFICATES')}
                        </StyledHeaderDropDownItem>
                    </Link>
                    <Link href={'/settings/profile'}>
                        <StyledHeaderDropDownItem>
                            {t('SETTINGS')}
                        </StyledHeaderDropDownItem>
                    </Link>
                    {isAdmin && (
                        <Link href={'/admin'}>
                            <StyledHeaderDropDownItem>
                                {t('ADMIN')}
                            </StyledHeaderDropDownItem>
                        </Link>
                    )}
                    <StyledHeaderDropDownItem onClick={localLogout}>
                        {t('LOG_OUT')}
                    </StyledHeaderDropDownItem>
                </StyledHeaderDropDownItems>
            }
        >
            <div className="header__toggle">
                <div
                    className={classNames('profile__icon', {
                        premium: profile.premium,
                    })}
                >
                    <img
                        alt="avatar"
                        src={profile.avatar50 ? profile.avatar50 : '/static/profile.jpg'}
                    />
                    <FontAwesomeIcon icon={faStar} />
                </div>
            </div>
        </Dropdown>
    );
};

export default withTranslation('header')(withRedux(AuthHeaderMenu));
