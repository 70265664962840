import styled from 'styled-components';

const StyledHeaderDropDownItems = styled.div`
    background: #fff;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 8px 8px rgba(33, 33, 33, 0.1);
    min-width: 180px;
    margin-top: 0px;
    padding: 6px 0;
    border: 1px solid #eaeaea;

    a {
        color: inherit;
        text-decoration: none;
    }

    @media screen and (max-width: 960px) {
        border-radius: 0;
        padding: 0;
        margin-top: -5px;
    }
`;

const StyledHeaderDropDownItem = styled.div`
    font-size: 1.05rem;
    font-family: var(--font-500);
    display: block;
    padding: 15px 25px;
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
        background: rgba(14, 19, 24, 0.05);
    }

    @media screen and (max-width: 960px) {
        color: var(--primary-blue);
        text-align: left;
        padding: 15px 20px;
        transition: unset;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export { StyledHeaderDropDownItem, StyledHeaderDropDownItems };
