import { NotificationManager } from 'react-notifications';

import { login, logout, register } from '@api/auth';

import { getServerError } from '@lib/http';

import * as types from '../../constants/user';

import { loadProfile, changeUserProfile } from './profileActions';

const changeUserIsAuthorized = (payload) => ({
    type: types.USER_CHANGE_IS_AUTHORIZED,
    payload,
});

const toggleAuthModal = (payload) => ({
    type: types.USER_TOGGLE_AUTH_MODAL,
    payload,
});

const toggleAuthModalIsLogin = (payload) => ({
    type: types.USER_TOGGLE_AUTH_MODAL_IS_LOGIN,
    payload,
});

const changeAuthModalDataIsLoading = (payload) => ({
    type: types.USER_TOGGLE_AUTH_MODAL_IS_LOADING,
    payload,
});

const changeAuthModalDataResult = (payload) => ({
    type: types.USER_CHANGE_AUTH_MODAL_DATA_RESULT,
    payload,
});

const localLogin = (email, password) =>
    async function (dispatch) {
        try {
            dispatch(changeAuthModalDataIsLoading(true));

            await login({ email, password });

            dispatch(loadProfile());
            dispatch(toggleAuthModal());
            dispatch(changeUserIsAuthorized(true));
        } catch (error) {
            const serverError = getServerError(error);

            throw new Error(
                serverError ? serverError.message : 'Произошла непредвиденная ошибка',
            );
        } finally {
            dispatch(changeAuthModalDataIsLoading(false));
        }
    };

const localRegister = (email, password, subscribed) =>
    async function (dispatch) {
        try {
            dispatch(changeAuthModalDataIsLoading(true));

            await register({ email, password, subscribed });
        } catch (error) {
            const serverError = getServerError(error);

            throw new Error(
                serverError ? serverError.message : 'Произошла непредвиденная ошибка',
            );
        } finally {
            dispatch(changeAuthModalDataIsLoading(false));
        }
    };

const localLogout = () =>
    async function (dispatch) {
        try {
            await logout();

            dispatch(changeUserIsAuthorized(false));
            dispatch(changeUserProfile({}));

            NotificationManager.info('Ждем вашего возвращения', 'Вы вышли из системы');
        } catch (error) {
            const serverError = getServerError(error);

            NotificationManager.error(
                serverError ? serverError.message : 'Произошла непредвиденная ошибка',
            );
        }
    };

export {
    toggleAuthModal,
    localLogin,
    localRegister,
    localLogout,
    toggleAuthModalIsLogin,
    changeAuthModalDataIsLoading,
    changeAuthModalDataResult,
    changeUserIsAuthorized,
};
