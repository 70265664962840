import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { apiUrl } from './env';

type HttpClientError = AxiosError<ServerError>;
type HttpClientRequestConfig<P> = AxiosRequestConfig<P>;

type ApiMethod<
    ResponseData = undefined,
    Params = undefined,
    RequestBody = Params,
> = Params extends undefined
    ? (
          params?: undefined,
          config?: HttpClientRequestConfig<RequestBody>,
      ) => Promise<AxiosResponse<ResponseData, RequestBody>>
    : (
          params: Params,
          config?: HttpClientRequestConfig<RequestBody>,
      ) => Promise<AxiosResponse<ResponseData, RequestBody>>;

const httpClient = axios.create({
    baseURL: apiUrl,
    timeout: 10000,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

const getServerError = (error: HttpClientError): ServerError['error'] | undefined => {
    return error.response?.data?.error;
};

const getServerErrorStatus = (error: HttpClientError): number | undefined => {
    return error.response?.status;
};

export { getServerError, getServerErrorStatus };
export type { HttpClientError, HttpClientRequestConfig, ApiMethod };

export default httpClient;
