import { createSelector } from 'reselect';

import { IRootState } from '@interfaces/store';

const isAuthorizedSelector = createSelector(
    (state: IRootState) => state.user.auth.isAuthorized,
    (value) => value,
);

const profileSelector = createSelector(
    (state: IRootState) => state.user.profile,
    (value) => value,
);

export { isAuthorizedSelector, profileSelector };
