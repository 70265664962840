import React, { FC, useMemo } from 'react';

import 'focus-visible';

import NextHead from 'next/head';

import { publicUrl } from '@lib/env';
import { useTranslation } from '@lib/i18n';

type Breadcrumb = { url: string; name: string };

type MetaProps = {
    title?: string;
    description?: string;
    breadcrumbs?: (Breadcrumb | undefined | null)[];
};

const Meta: FC<MetaProps> = (props) => {
    const { breadcrumbs, title, description } = props;

    const { t, lang } = useTranslation('meta');

    const breadcrumbsLayout = useMemo(() => {
        if (!breadcrumbs) {
            return null;
        }

        const layout = {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: (breadcrumbs.filter(Boolean) as Breadcrumb[]).map(
                (item, index) => ({
                    '@type': 'ListItem',
                    position: index + 1,
                    name: item.name,
                    item: `${publicUrl}/${lang}${item.url}`,
                }),
            ),
        };

        return JSON.stringify(layout);
    }, [breadcrumbs, lang]);

    return (
        <NextHead>
            <title>{title || t('DEFAULT_TITLE')}</title>

            <meta name="description" content={description || t('DEFAULT_DESCRIPTION')} />

            {breadcrumbsLayout && (
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: breadcrumbsLayout }}
                />
            )}
        </NextHead>
    );
};

export { Meta };
