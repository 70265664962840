import React, { FC } from 'react';

import { default as DropdownBase } from 'rc-dropdown';
import { DropdownProps } from 'rc-dropdown/lib/Dropdown';
import styled from 'styled-components';

import 'rc-dropdown/assets/index.css';

export const StyledDropdownItems = styled.div`
    box-shadow: 0px 4px 8px 0 rgba(177, 189, 195, 0.54);
    background: white;
    border-radius: var(--border-radius-m);
    overflow: hidden;
    border: 1px solid rgba(221, 221, 221, 0.8);
    font-family: var(--font-500);
`;

export const StyledDropdownItem = styled.div`
    cursor: pointer;
    background: #fff;
    padding: 6px 10px;
    font-family: var(--font-500);
    color: #333;
    font-size: 16px;
    transition: 0.2s;

    &:hover {
        background: #f2f9fc;
    }
`;

export const StyledDropdownSelector = styled.div<{
    width?: string | number;
    disabled?: boolean;
}>`
    box-sizing: border-box;
    width: ${({ width }) => (width ? width : '100%')};
    padding: 8px 10px;
    border: 1px solid #ddd;
    border-radius: var(--border-radius-m);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    ${({ disabled }) =>
        disabled &&
        `
        cursor: no-drop;
        color: var(--light-grey);
        --text-color-primary: var(--light-grey);
    `}
`;

export const Arrow = ({ size, color = 'var(--text-color-primary)' }) => {
    return (
        <svg viewBox="0 0 1 0.5" style={{ width: size, height: size / 2 }}>
            <path
                d="M 0.15 0 L 0.85 0 Q 0.95 0.05 0.85 0.15 L 0.6 0.4 Q 0.5 0.5 0.4 0.4 L 0.15 0.15 Q 0.05 0.05 0.15 0"
                fill={color}
            />
        </svg>
    );
};

const Dropdown: FC<DropdownProps> = ({ children, ...props }) => {
    return <DropdownBase {...props}>{children}</DropdownBase>;
};

export default Dropdown;
