import { merge } from 'lodash';

import { ICertificateProgress, IProfile } from '@interfaces/user';
import httpClient, { ApiMethod } from '@lib/http';

type UploadAvatarResponse = {
    avatar50: { id: string };
    avatar200: { id: string };
};

const getProfile: ApiMethod<IProfile> = (_, config) => {
    return httpClient.get('/api/v1/user/profile', config);
};

const getUser: ApiMethod<unknown, { userId: string }> = (params, config) => {
    const { userId } = params;

    return httpClient.get(`/api/v1/user/${userId}`, config);
};

const updateUser: ApiMethod<unknown, { userId: string; data: unknown }> = (
    params,
    config,
) => {
    const { userId, data } = params;

    return httpClient.put(`/api/v1/user/${userId}`, data, config);
};

const getCertificateProgress: ApiMethod<ICertificateProgress> = (_, config) => {
    return httpClient.get('/api/v1/trainer/progress', config);
};

const uploadAvatar: ApiMethod<UploadAvatarResponse, { data: FormData }, FormData> = (
    params,
    config,
) => {
    const { data } = params;

    return httpClient.post(
        '/api/v1/avatar',
        data,
        merge({ headers: { 'Content-Type': 'multipart/form-data' } }, config),
    );
};

const deleteAvatar: ApiMethod<undefined, { avatarId: string }> = (params, config) => {
    const { avatarId } = params;

    return httpClient.delete(`/api/v1/avatar/${avatarId}`, config);
};

const addFavouriteQuestion: ApiMethod<undefined, { questionId: string }> = (
    params,
    config,
) => {
    return httpClient.post('/api/v1/user/favourite-questions', params, config);
};

const deleteFavouriteQuestion: ApiMethod<undefined, { questionId: string }> = (
    params,
    config,
) => {
    const { questionId } = params;

    return httpClient.delete(`/api/v1/user/favourite-questions/${questionId}`, config);
};

export {
    getProfile,
    getUser,
    updateUser,
    getCertificateProgress,
    uploadAvatar,
    deleteAvatar,
    addFavouriteQuestion,
    deleteFavouriteQuestion,
};
