import { connect } from 'react-redux';
import { localLogout } from '../../../../store/actions/user/authActions';

const withRedux = (Component) => {
    const mapStateToProps = ({ user: { auth, profile } }) => ({
        isAuthorized: auth.isAuthorized,
        profile,
    });

    const mapDispatchToProps = (dispatch) => ({
        localLogout: () => dispatch(localLogout()),
    });

    return connect(mapStateToProps, mapDispatchToProps)(Component);
};

export default withRedux;
