import { useCallback, useState, FC } from 'react';

import { faBars, faSortDown, faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { motion, useScroll, useTransform } from 'framer-motion';
import dynamic from 'next/dynamic';

import { useDispatch, useSelector } from 'react-redux';

import { Link, useTranslation } from '@lib/i18n';

import { toggleAuthModal } from '@store/actions/user/authActions';

import { isAuthorizedSelector, profileSelector } from '@store/selectors/user';

import Dropdown from '../Dropdown/index';

import AuthHeaderMenu from './AuthMenu';
import { StyledHeaderDropDownItems, StyledHeaderDropDownItem } from './HeaderDropdown';

const HeaderSearch = dynamic(() => import('./Search').then((mod) => mod.HeaderSearch), {
    ssr: false,
});

const HandbookModal = dynamic(
    () => import('@components/HandbookModal').then((mod) => mod.HandbookModal),
    {
        ssr: false,
    },
);

const HeaderBanner = dynamic(() => import('./HeaderBanner/HeaderBanner'), {
    ssr: false,
});

import './index.scss';

interface IProps {
    isFullWidth?: boolean;
    isTransparentBg?: boolean;
}

const Header: FC<IProps> = ({ isFullWidth, isTransparentBg }) => {
    const isAuthorized = useSelector(isAuthorizedSelector);
    const profile = useSelector(profileSelector);
    const dispatch = useDispatch();

    const [isMobileMenuOpened, toggleMenu] = useState(false);
    const [isOpenHandbook, setIsOpenHandbook] = useState(false);

    const toggleHandbookModal = useCallback(() => {
        setIsOpenHandbook(!isOpenHandbook);
    }, [isOpenHandbook, setIsOpenHandbook]);

    const { t } = useTranslation('header');

    const { scrollY } = useScroll();

    const backgroundTransform = useTransform(scrollY, [0, 130], ['#ffffff0', '#fff']);
    const boxShadowTransform = useTransform(
        scrollY,
        [0, 130],
        ['1px 0px 2px #0000000', '1px 0px 2px #0000003b'],
    );

    return (
        <motion.header
            className="header"
            style={{
                background: isTransparentBg ? backgroundTransform : 'var(--white)',
                boxShadow: isTransparentBg ? boxShadowTransform : '1px 0px 2px #0000003b',
            }}
        >
            <div
                className={cn('content', {
                    'content--fullwidth': isFullWidth,
                })}
            >
                <Dropdown
                    overlayClassName="header_dropdown_items"
                    trigger={['click']}
                    animation="slide-up"
                    onVisibleChange={toggleMenu}
                    visible={isMobileMenuOpened}
                    overlay={
                        <StyledHeaderDropDownItems>
                            <Link href="/guide">
                                <StyledHeaderDropDownItem>
                                    {t('GUIDE')}
                                </StyledHeaderDropDownItem>
                            </Link>
                            <Link href="/trainer">
                                <StyledHeaderDropDownItem>
                                    {t('ONLINE_TRAINER')}
                                </StyledHeaderDropDownItem>
                            </Link>
                            <Link href="/sandbox">
                                <StyledHeaderDropDownItem>
                                    {t('SANDBOX')}
                                </StyledHeaderDropDownItem>
                            </Link>
                            <Link href="/handbook">
                                <StyledHeaderDropDownItem>
                                    {t('HANDBOOK')}
                                </StyledHeaderDropDownItem>
                            </Link>
                            {!profile.premium && (
                                <Link href={'/premium/'}>
                                    <StyledHeaderDropDownItem>
                                        {t('PREMIUM')}
                                    </StyledHeaderDropDownItem>
                                </Link>
                            )}
                        </StyledHeaderDropDownItems>
                    }
                >
                    <div className="header__mobile-menu">
                        <div
                            className="toggle"
                            onClick={() => toggleMenu(!isMobileMenuOpened)}
                        >
                            <FontAwesomeIcon
                                icon={isMobileMenuOpened ? faTimes : faBars}
                            />
                        </div>
                    </div>
                </Dropdown>

                <div className="header__logo">
                    <Link href="/" className="logo-link">
                        <img
                            src="/static/logo.svg"
                            width="160"
                            height="32"
                            alt="Логотип sql academy"
                        />
                    </Link>
                </div>

                <div className="header__full-menu">
                    <nav className="header__nav">
                        <div className="nav-links">
                            <Link href="/guide" className="nav-item">
                                <span>{t('GUIDE')}</span>
                            </Link>

                            <Link href="/trainer" className="nav-item">
                                <span>{t('ONLINE_TRAINER')}</span>
                            </Link>

                            <Dropdown
                                overlayClassName="header_dropdown_items"
                                trigger={['click']}
                                animation="slide-up"
                                overlay={
                                    <StyledHeaderDropDownItems>
                                        <Link href="/sandbox">
                                            <StyledHeaderDropDownItem>
                                                {t('SANDBOX')}
                                            </StyledHeaderDropDownItem>
                                        </Link>
                                        <Link href="/handbook">
                                            <StyledHeaderDropDownItem>
                                                {t('HANDBOOK')}
                                            </StyledHeaderDropDownItem>
                                        </Link>
                                        <Link href="/interview-questions">
                                            <StyledHeaderDropDownItem>
                                                {t('INTERVIEW_QUESTIONS')}
                                            </StyledHeaderDropDownItem>
                                        </Link>
                                    </StyledHeaderDropDownItems>
                                }
                            >
                                <div className="nav-item">
                                    {t('MORE')}
                                    <FontAwesomeIcon
                                        icon={faSortDown}
                                        style={{ marginTop: -4, marginLeft: 10 }}
                                    />
                                </div>
                            </Dropdown>
                        </div>
                    </nav>
                    <div className="header__rightbar">
                        <div className="search">
                            {!isTransparentBg && (
                                <HeaderSearch openModal={toggleHandbookModal} />
                            )}
                        </div>
                        {!profile.premium && (
                            <Link href="/premium" className="nav-item premium-nav-item">
                                <FontAwesomeIcon icon={faStar} />

                                <span className="premium-nav-item__text">
                                    {t('PREMIUM')}
                                </span>
                            </Link>
                        )}

                        <div className="header__auth">
                            {isAuthorized ? (
                                <AuthHeaderMenu />
                            ) : (
                                <div className="auth__links">
                                    <div
                                        onClick={() => dispatch(toggleAuthModal())}
                                        className="link__signin"
                                    >
                                        {t('LOGIN')}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <HeaderBanner />
            {isOpenHandbook && (
                <HandbookModal
                    isOpen={isOpenHandbook}
                    toggleModal={toggleHandbookModal}
                />
            )}
        </motion.header>
    );
};

export default Header;
