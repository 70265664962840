import httpClient, { ApiMethod } from '@lib/http';

const forgotPassword: ApiMethod<undefined, { email: string }> = (params, config) => {
    const { email } = params;

    return httpClient.post('/api/v1/auth/forgot-password', { email }, config);
};

const resetPassword: ApiMethod<
    undefined,
    { email: string; token: string; password: string }
> = (params, config) => {
    return httpClient.post('/api/v1/auth/reset-password', params, config);
};

const login: ApiMethod<undefined, { email: string; password: string }> = (
    params,
    config,
) => {
    return httpClient.post('/api/v1/auth/login', params, config);
};

const register: ApiMethod<
    undefined,
    { email: string; password: string; subscribed?: boolean }
> = (params, config) => {
    return httpClient.post('/api/v1/auth/register', params, config);
};

const logout: ApiMethod = (_, config) => {
    return httpClient.get('/api/v1/auth/logout', config);
};

export { forgotPassword, resetPassword, login, register, logout };
